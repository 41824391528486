import React from "react";
import "./Contact.scss";
import BreadCrumb from "../../Components/Global/BreadCrumb/BreadCrumb";
import { Link } from 'react-router-dom';
import {Button} from '@mui/material'
import Map from "../Home/Components/Map/Map";
import { BiMapPin } from "react-icons/bi";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";


function Contact() {

  if(window.Email){
    window.Email.send({
      Host : "smtp.elasticemail.com",
      Username : "iqbal.iqbal@mssoftpc.com",
      Password : "1FB54302D73B1A1509C9F3DE7B6FF5E7F101",
      To : 'mssoftpc.com@gmail.com',
      From : "iqbal.iqbal@mssoftpc.com",
      Subject : "This is the subject from React",
      Body : "And this is the body"
  }).then(
    message => alert(message)
  );
  }
    
  return (
    <div className="Contact">
      <BreadCrumb img={"/assets/BreadCrumb/breadcrumb.png"} title={"Contact"} />
      <h5 className="headingTitle">Contact us</h5>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="cardBox">
                <h3 className="title">Get in Touch With Us Today</h3>
                <form className="form" method="post">
                    <div className="form-group">
                        <label htmlFor="" className="form-label">Name <span className="required">*</span></label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="" className="form-label">Email <span className="required">*</span></label>
                        <input type="email" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="" className="form-label">Company <span className="required">*</span></label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="" className="form-label">Message <span className="required">*</span></label>
                        <textarea className="form-control" rows={5}></textarea>
                    </div>
                    <div className="checkbox-group">
                        <input type="checkbox" name="" id="checkboxForm" />
                        <label htmlFor="checkboxForm">I consent to have my information stored for contact purposes.</label>
                    </div>
                    <Button type="submit" className="primaryBtn">Submit</Button>
                </form>
            </div>
          </div>
          {/* 2 */}
          <div className="col-md-6">
                <div className="cardBox detailsbox">
                <h3 className="title">FIND OUR COMPANY</h3>
                    <div className="addressBox">
                        <span><BiMapPin/></span>
                        <div className="subtitle">  Richard House, Martin Rd, Kampala, Uganda </div>
                    </div>
                    <div className="addressBox">
                        <span><IoMdMail/></span>
                        <div className="subtitle">  <Link to={"mailto: msaleem@imagine-pharma.com"}>msaleem@imagine-pharma.com</Link> </div>
                    </div>
                    <div className="addressBox">
                        <span><IoCall/></span>
                        <div className="subtitle">  <Link to={"tel:+256 744 111125"}>+256 744 111125</Link> </div>
                    </div>
                   
                </div>
          </div>
        </div>
        {/* map */}
        <Map/>
      </div>
    </div>
  );
}

export default Contact;
