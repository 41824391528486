import React from "react";
import BreadCrumb from "../../Components/Global/BreadCrumb/BreadCrumb";
import "./About.scss";
import {Button} from '@mui/material'
function About() {
  return (
    <div className="About">
      <BreadCrumb
        img={"/assets/BreadCrumb/DJP_2419 1.png"}
        title={"About us"}
      />
      <h1 className="headingTitle">About us</h1>
      <div className="container mt-5">
        <div className="sectionAbout">
          <div className="row">
            <div className="col-md-7">
              <div className="contentBox">
                <p>
                  <b>
                    Imagine Pharmaceuticals Limited (Imagine Pharma) is a
                    Ugandan registered company{" "}
                  </b>{" "}
                  with Imagine Design and Build Limited as the parent company.
                  "The essence of our culture is to build best practices that
                  meet global standards in every aspect of our business." We
                  aspire to be the Biggest in active Manufacturing, R&D and
                  Distribution of IVDs, Medical supplies, Preventive and
                  Palliative care medicines and devices that meet the needs of
                  the region.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="doubleImgBox">
                <img src={"/assets/About/DJP_2271 1.png"} alt="" />
                <img src={"/assets/About/DJP_2463 1.png"} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="sectionFocus mt-5">
          <div className="row">
            <div className="col-md-4">
              <div className="imgBox">
                <img src={"/assets/About/DJP_2254 1.png"} alt="" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="contentBox">
                <p>
                  <span className="biggerText">OUR FOCUS </span> Our key primary
                  focus is on high burden public sector health care in-puts that
                  have wide-ranging applications across all fields of health
                  care with a specific interest in fields that are largely donor
                  funded. Our secondary focus is in rare specific high value use
                  items in areas of Oncology, Gastroenterology, Antidiabetic,
                  Antibiotics, Cardiovascular, Pain Management, Gynaecology
                  among others.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionStength mt-5">
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="contentBox">
                <p>
                  <span className="biggerText">OUR STRENGTH </span> Cultivating and Strengthening strategic alliances in-country, regionally and internationally to facilitate establishment of Regional Manufacturing Hubs by leveraging on smooth Technological Transfer and Absorption in Low and Middle income Countries. By Leveraging on already commercialized and proven state-of-the-art technologies, QMS, Best practices and Regulation Compliant Products, Imagine Pharma intends to establish manufacturing plants in the Region that will comply with the stringent prestigious US FDA, CE, AND WHO Good Manufacturing Practices and other unique foreign countries’ health regulations.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="imgBox">
                <img src={"/assets/About/DJP_2271 1.png"} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BtnArea w-100 text-center mt-5 mb-5">
         <Button className="primaryBtn">Our Team</Button>
      </div>
    </div>
  );
}

export default About;
