import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home/Home";
import Main from "../pages/Main";
import Contact from "../pages/Contact/Contact";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import About from "../pages/About/About";
import NewsRoom from "../pages/NewsRoom/NewsRoom";
import Warehouse from "../pages/Warehouse/Warehouse";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import TermCondition from "../pages/TermCondition/TermCondition";
import Innovation from "../pages/Innovation/Innovation";
import Milestones from "../pages/Milestones/Milestones";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main/>,

        children: [
            {
                path:"/",
                element:<Home/>,
            },
            {
                path:"/contact-us",
                element:<Contact/>
            },
            {
                path:"/about-us",
                element:<About/>
            },
            {
                path:"/news-room",
                element:<NewsRoom/>
            },
            {
                path:"/warehouse",
                element:<Warehouse/>
            },
            {
                path:"/innovation",
                element:<Innovation/>
            },
            {
                path:"/milestones",
                element:<Milestones/>
            },
            {
                path:"/privacy-policy",
                element:<PrivacyPolicy/>
            },
            {
                path:"/term-condition",
                element:<TermCondition/>
            },
            {
                path:"/*",
                element:<PageNotFound/>
            }
        ]
    }
])

export default router