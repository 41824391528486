import React from 'react'
import './About4.scss'

function About4() {
  return (
    <div className="About4">
        <div className="container">
        <div className="row">
            <div className="col-md-7">
                <div className="content-box">
                    <h4 className="title">OUR STRENGTH</h4>
                    <div className="content">
                        <p>Cultivating and Strengthening strategic alliances in-country, regionally and internationally to facilitate establishment of Regional Manufacturing Hubs by leveraging on smooth Technological Transfer and Absorption in Low and Middle income Countries. By Leveraging on already commercialized and proven state-of-the-art technologies, QMS, Best practices and Regulation Compliant Products, Imagine Pharma  intends to establish manufacturing plants in the Region that will comply with the  stringent prestigious US FDA, CE, AND WHO Good Manufacturing Practices and  other unique foreign countries' health regulations.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-5">
                <div className="imgBox">
                    <img src='/assets/Home/last1.png' className='' alt=''/>
                    <img src='/assets/Home/last2.png' className='' alt=''/>
                    <img src='/assets/Home/last3.png' className='' alt=''/>
                    <img src='/assets/Home/last4.png' className='' alt=''/>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default About4