import React from 'react'
import './Map.scss'

function Map() {
  return (
    <div className="Map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3490.1601977569067!2d77.70563017508371!3d28.98262356809752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c652b9eb962cb%3A0x10d9d02499e75015!2sMSSOFTPC.COM!5e0!3m2!1sen!2sin!4v1719676681614!5m2!1sen!2sin"
            width={"100%"}
            height={350}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title='map'
        />

    </div>
  )
}

export default Map