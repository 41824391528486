import React from 'react'
import { RiFacebookBoxFill } from "react-icons/ri";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Button } from '@mui/material';
import './SocialMediaWidget.scss'
function SocialMediaWidget() {
  return (
    <div className="SocialMediaWidget">
    <h5 className="title">Follow us</h5>
    <ul className="d-flex">
        <li><Button><RiFacebookBoxFill/></Button></li>
        <li><Button><FaSquareXTwitter/></Button></li>
        <li><Button><FaInstagramSquare/></Button></li>
        <li><Button><FaLinkedin/></Button></li>
    </ul>
</div>
  )
}

export default SocialMediaWidget