import React from 'react'
import './Innovation.scss'
import BreadCrumb from '../../Components/Global/BreadCrumb/BreadCrumb'
function Innovation() {
  return (
    <div className="Innovation">
        <BreadCrumb img={"/assets/BreadCrumb/DJP_2209 1.png"}/>
        <h1 className="headingTitle">Innovation</h1>
        <div className="container mt-5">
            {/* BoxVision */}
            <div className="BoxVision">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="title">Vision</h3>
                        <div className="content">
                            <p>To be the Leader in R&D, Manufacturing and Distribution of quality and latest medical devices technology and Rare High Value Medicines in Africa.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="imgBox">
                            <img src='/assets/Innovation/DJP_2463 1.png' alt=''/>
                            <img src='/assets/Innovation/DJP_2288 2.png' alt=''/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="BoxVision">
                <div className="row">
                    <div className="col-md-6">
                        <div className="doubleImgBox">
                            <img src="/assets/Innovation/DJP_2254 1.png" alt="" />
                            <img src="/assets/Innovation/DJP_2237 5.png" alt="" />
                            <img src="/assets/Innovation/DJP_2238 2.png" alt="" />
                            <img src="/assets/Innovation/DJP_2271 1.png" alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="title">Mission</h3>
                        <div className="content">
                            <p>To create value for our clients through providing innovative latest technology products and services for their healthcare delivery by guaranteeing reliable and Consistent supplies, and providing after sale service for our wide range of medical devices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Innovation